import React from "react";
import CounselApp from "@toorak/tc-counsel-web-app";
import { Header } from "../create-loan/home/Header";
import { publicClient} from "@toorak/tc-common-fe-sdk";

function CounselWrapper() {
  return (
    <div>
      <Header
        value={0}
        setTabValue={0}
        showTab={false}
        showNotificationIcon={true}
      />
        <CounselApp publicClient={publicClient}  />
    </div>
  );
}

export default CounselWrapper;
