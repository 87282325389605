/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { useState } from "react";
import { LoanStage, UserRole } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import moment from "moment";
import { ObjectType } from "../masterView/common";
import { getFiltersData, getLoansList } from "../network/apiService";
import { isRole } from "../utils/AccessManagement";
import { prePurchaseInitiationStates } from "../utils/constants";
import { getBundles } from "../stores/SettlementAndPurchase/SettlementAndPurchase.action";

const loanSourceFields = [
  "originator.sellerName",
  "loan.loanInfo.primaryLoanId",
  "loan.loanState",
  "loan.loanType",
  "bundle.bundleStatus",
  "bundle.bundleId",
  "bundle.bundleLandmarks",
  "bundle.closingDate",
  "loan.loanStage",
  "loan.loanDetailId.originatorPartyId",
  "result.loanResult.summary.fundingSource",
  "loan.onHoldBy",
  "loan.loanInfo.expectedClosingDate",
  "loan.loanCredit.purchaseDate",
  "loan.result.loanResult.summary",
  "loan.loanDetailId.loanId",
  "loan.initialLoanSubmitionDate"
];

const tkopSourceFields = [
  "loan.loanDetailId.toorakLoanId",
  "loan.loanDetailId.originatorPartyId",
  "loan.loanInfo.takeoutPartner",
  "loan.loanInfo.primaryLoanId",
  "originator.accountName",
  "loan.loanState",
  "loan.loanRuleVersions",
  "loan.loanType",
  "loan.loanStage",
  "loan.loanSubmissionDate",
  "loan.loanEconomics.originalLoanAmount",
  "loan.loanEconomics.interestRateAsOfCutOffDate",
  "loan.rateLockExpiryDate",
  "loan.loanDetailId.loanConfigId.rateLockPeriod",
  "loan.eligibleTakeoutPartners.takeoutPartnerPartyId",
  "result.loanResult.loanPricing.finalToorakPrice"
];

const bundleSourceFields = [
  "loanType",
  "loans.loanId",
  "originatorPartyIdInfo.sellerName",
  "bundleId",
  "bundleStatus"
];

let defaultMustArrayForDDNotInitiatedAssignedLoan = [
  {
    terms: {
      "loan.loanType.raw": ["30 Year Loan"]
    }
  },
  {
    terms: {
      "loan.loanState.raw": ["Initial Review"]
    }
  },
  {
    exists: {
      field: "loan.loanInfo.takeoutPartner.raw"
    }
  }
  // {
  //   terms: {
  //     "loan.loanInfo.takeoutPartner.raw": [partnerId ? `${partnerId}` : '']
  //   }
  // }
];

const defaultMustArrayForDDInitiatedAssignedLoan = [
  {
    terms: {
      "loan.loanType.raw": ["30 Year Loan"]
    }
  },
  {
    terms: {
      "loan.loanState.raw": [
        "Due Diligence Review",
        "Final Review",
        "Approved",
        "Purchase Initiated",
        "Purchased"
      ]
    }
  },
  {
    exists: {
      field: "loan.loanInfo.takeoutPartner.raw"
    }
  }
  // {
  //   terms: {
  //     "loan.loanInfo.takeoutPartner.raw": [partnerId ? `${partnerId}` : '']
  //   }
  // }
];

const defaultMustArrayForUnassignedLoan = [
  {
    terms: {
      "loan.loanType.raw": ["30 Year Loan"]
    }
  },
  {
    terms: {
      "loan.loanState.raw": ["Initial Review"]
    }
  },
  {
    bool: {
      should: [
        {
          terms: {
            "loan.loanInfo.takeoutPartnerStatus.raw": ["PENDING"]
          }
        },
        {
          bool: {
            must_not: [
              {
                exists: {
                  field: "loan.loanInfo.takeoutPartner.raw"
                }
              }
            ]
          }
        }
      ]
    }
  }
];

const defaultMustNotArray = [
  {
    terms: {
      "loan.loanStage.raw": [LoanStage.fes, LoanStage.tv]
    }
  },
  {
    terms: {
      "loan.loanState.raw": [
        LoanStatusEnum.ExcelError,
        LoanStatusEnum.ExcelSuccess,
        LoanStatusEnum.DataEntry,
        LoanStatusEnum.Rejected,
        LoanStatusEnum.Submit,
        "Withdrawn"
      ]
    }
  }
];

const unAssignedLoanAggsQuery = {
  unassignedLoanAmount: {
    sum: {
      script: {
        source:
          "if(doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') &&  doc['loan.loanEconomics.originalLoanAmount.raw'].size() >0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',','').replace('$',''));} else {return 0;} "
      }
    }
  }
};

const loansAggsQuery = {
  concetrationFail: {
    filter: {
      bool: {
        should: [
          {
            terms: {
              "result.loanResult.dbWhl.concentration.raw": ["FAIL"]
            }
          },
          {
            terms: {
              "result.loanResult.msWhl.concentration.raw": ["FAIL"]
            }
          }
        ]
      }
    }
  },
  eligibilityFail: {
    filter: {
      bool: {
        should: [
          {
            terms: {
              "result.loanResult.dbWhl.eligibility.raw": ["FAIL"]
            }
          },
          {
            terms: {
              "result.loanResult.msWhl.eligibility.raw": ["FAIL"]
            }
          }
        ]
      }
    }
  },
  concentrationAndEligibilityFail: {
    filter: {
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  terms: {
                    "result.loanResult.dbWhl.concentration.raw": ["FAIL"]
                  }
                },
                {
                  terms: {
                    "result.loanResult.msWhl.concentration.raw": ["FAIL"]
                  }
                }
              ]
            }
          },
          {
            bool: {
              should: [
                {
                  terms: {
                    "result.loanResult.dbWhl.eligibility.raw": ["FAIL"]
                  }
                },
                {
                  terms: {
                    "result.loanResult.msWhl.eligibility.raw": ["FAIL"]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  }
};

function addGlobalFilters(arr: any[], filterPayload: any) {
  if (Object.keys(filterPayload)?.length) {
    Object.keys(filterPayload).forEach((val: any) => {
      if (filterPayload[val]?.length) {
        if (val.includes("properties")) {
          arr.push({
            nested: {
              path: "properties",
              query: {
                bool: {
                  must: {
                    terms: {
                      [val]: filterPayload[val]
                    }
                  }
                }
              }
            }
          });
        } else {
          arr.push({
            terms: {
              [val]: filterPayload[val]
            }
          });
        }
      }
    });
  }
  return arr;
}

const filterModel = (data: [], mustNot: []) => {
  return {
    query: {
      bool: {
        must: [...data],
        must_not: mustNot
      }
    }
  };
};

const getEligibilityAndConcentrationQuery = (key: string, options: any) => {
  return {
    bool: {
      should: [
        {
          terms: {
            [`result.loanResult.dbWhl.${key}.raw`]: options
          }
        },
        {
          terms: {
            [`result.loanResult.msWhl.${key}.raw`]: options
          }
        }
      ]
    }
  };
};

const getFundingSourceAvailableQuery = (options: any) => {
  return options.map((option: string) => {
    return {
      terms: {
        [`result.loanResult.summary.${option}.raw`]: ["Y"]
      }
    };
  });
};

const getFundingSourceQuery = (options: string[], mustNot: any): ObjectType => {
  if (options.length > 1) {
    return { mustQueryObj: undefined, updatedMustNot: mustNot };
  }
  const query: ObjectType = {
    notAssigned: {
      bool: {
        should: [
          {
            terms: {
              "result.loanResult.summary.fundingSource.raw": [""]
            }
          },
          {
            bool: {
              must_not: {
                exists: {
                  field: "result.loanResult.summary.fundingSource.raw"
                }
              }
            }
          }
        ]
      }
    },
    assigned: [
      {
        bool: {
          should: [
            {
              terms: {
                "result.loanResult.summary.fundingSource.raw": [""]
              }
            },
            {
              bool: {
                must_not: {
                  exists: {
                    field: "result.loanResult.summary.fundingSource.raw"
                  }
                }
              }
            }
          ]
        }
      }
    ]
  };
  if (options[0] === "notAssigned") {
    return { mustQueryObj: query.notAssigned, updatedMustNot: mustNot };
  }
  if (options[0] === "assigned") {
    return {
      mustQueryObj: undefined,
      updatedMustNot: [...mustNot, ...query.assigned]
    };
  }
  return {};
};

const getBundleEligibilityAndConcentrationQuery = (
  key: string,
  options: any
) => {
  return {
    nested: {
      path: "loanResults",
      query: {
        bool: {
          should: [
            {
              terms: {
                [`loanResults.dbWhl.${key}.raw`]: options
              }
            },
            {
              terms: {
                [`loanResults.msWhl.${key}.raw`]: options
              }
            }
          ]
        }
      }
    }
  };
};

const getBundleFundingSourceAvailableQuery = (options: any) => {
  return {
    nested: {
      path: "loanResults",
      query: {
        bool: {
          should: options.map((option: string) => {
            return {
              terms: {
                [`loanResults.summary.${option}.raw`]: ["Y"]
              }
            };
          })
        }
      }
    }
  };
};

const getBundleFundingSourceQuery = (
  options: string[],
  mustNot: any
): ObjectType => {
  if (options.length > 1) {
    return { mustQueryObj: undefined, updatedMustNot: mustNot };
  }
  const query: ObjectType = {
    notAssigned: {
      nested: {
        path: "loanResults",
        query: {
          bool: {
            should: [
              {
                terms: {
                  "loanResults.summary.fundingSource.raw": [""]
                }
              },
              {
                bool: {
                  must_not: {
                    exists: {
                      field: "loanResults.summary.fundingSource.raw"
                    }
                  }
                }
              }
            ]
          }
        }
      }
    },
    assigned: [
      {
        nested: {
          path: "loanResults",
          query: {
            bool: {
              should: [
                {
                  terms: {
                    "loanResults.summary.fundingSource.raw": [""]
                  }
                },
                {
                  bool: {
                    must_not: {
                      exists: {
                        field: "loanResults.summary.fundingSource.raw"
                      }
                    }
                  }
                }
              ]
            }
          }
        }
      }
    ]
  };
  if (options[0] === "notAssigned") {
    return { mustQueryObj: query.notAssigned, updatedMustNot: mustNot };
  }
  if (options[0] === "assigned") {
    return {
      mustQueryObj: undefined,
      updatedMustNot: [...mustNot, ...query.assigned]
    };
  }
  return {};
};

const getClosingDate = (options: any, isQueryForBundle?: boolean) => {
  const range = options[0];
  return {
    range: {
      [`${isQueryForBundle ? "" : "bundle."}${range.key}`]: {
        gte: moment(range.startDate).format("yyyy-MM-DD").toString(),
        lte: moment(range.endDate).format("yyyy-MM-DD").toString(),
        format: "yyyy-MM-dd"
      }
    }
  };
};

const getRateLockExpiryDate = (options: any) => {
  const range = options[0];
  return {
    range: {
      [`loan.${range.key}`]: {
        gte: moment(range.startDate).format("yyyy-MM-DD").toString(),
        lte: moment(range.endDate).format("yyyy-MM-DD").toString(),
        format: "yyyy-MM-dd"
      }
    }
  };
};

const getloanSubmissionDate = (options: any) => {
  const range = options[0];
  return {
    range: {
      [`loan.${range.key}.raw`]: {
        gte: moment(range.startDate).format("yyyy-MM-DD").toString(),
        lte: moment(range.endDate).format("yyyy-MM-DD").toString(),
        format: "yyyy-MM-dd"
      }
    }
  };
};

const getPurchaseDate = (options: any, isQueryForBundle?: boolean) => {
  const range = options[0];
  return {
    nested: {
      path: `${
        isQueryForBundle ? "bundleLandmarks" : "loan.loanDetailId.loanLandmarks"
      }`,
      query: {
        bool: {
          must: [
            {
              terms: {
                [`${
                  isQueryForBundle
                    ? "bundleLandmarks"
                    : "loan.loanDetailId.loanLandmarks"
                }.value.raw`]: ["Purchased"]
              }
            },
            {
              term: {
                [`${
                  isQueryForBundle
                    ? "bundleLandmarks"
                    : "loan.loanDetailId.loanLandmarks"
                }.name.raw`]: isQueryForBundle
                  ? "update_bundle_status"
                  : "update_status"
              }
            },
            {
              range: {
                [`${
                  isQueryForBundle
                    ? "bundleLandmarks.createdOn"
                    : "loan.loanDetailId.loanLandmarks.date"
                }`]: {
                  gte: moment(range.startDate).format("yyyy-MM-DD").toString(),
                  lte: moment(range.endDate).format("yyyy-MM-DD").toString(),
                  format: "yyyy-MM-dd"
                }
              }
            }
          ]
        }
      }
    }
  };
};

const getLoanStateQuery = (options: any) => {
  let updatedOptions: string[] = [];
  options.forEach((option: string) => {
    if (option === "Initial Review - Approved") {
      updatedOptions = [...prePurchaseInitiationStates, ...updatedOptions];
    } else {
      updatedOptions = [...updatedOptions, option];
    }
  });
  return {
    terms: {
      "loan.loanState.raw": updatedOptions
    }
  };
};

function createQuery(
  options: ObjectType = {},
  model: Function,
  type: string,
  mustNot: any[],
  isQueryForBundle?: boolean
) {
  const keys = Object.keys(options);
  let updatedMustNot = [...mustNot];
  let validQuery = false;
  const queryObject: any = type === "filter" ? [] : {};
  keys.forEach((key) => {
    let mustQuery: any = {};
    let isQueryAnArray = false;

    switch (key) {
      case "eligibility":
      case "concentration":
        mustQuery = getEligibilityAndConcentrationQuery(key, options[key]);
        break;
      case "fundingSourceAvailable":
        mustQuery = getFundingSourceAvailableQuery(options[key]);
        isQueryAnArray = true;
        break;
      case "fundingSourceAssignment":
        mustQuery = getFundingSourceQuery(
          options[key],
          updatedMustNot
        )?.mustQueryObj;
        updatedMustNot =
          getFundingSourceQuery(options[key], updatedMustNot)?.updatedMustNot ??
          updatedMustNot;
        break;
      case "bundleEligibility":
        mustQuery = getBundleEligibilityAndConcentrationQuery(
          "eligibility",
          options[key]
        );
        break;
      case "bundleConcentration":
        mustQuery = getBundleEligibilityAndConcentrationQuery(
          "concentration",
          options[key]
        );
        break;
      case "bundleFundingSourceAvailable":
        mustQuery = getBundleFundingSourceAvailableQuery(options[key]);
        break;
      case "bundleFundingSourceAssignment":
        mustQuery = getBundleFundingSourceQuery(
          options[key],
          updatedMustNot
        )?.mustQueryObj;
        updatedMustNot =
          getBundleFundingSourceQuery(options[key], updatedMustNot)
            ?.updatedMustNot ?? updatedMustNot;
        break;
      case "closingDate":
        mustQuery = getClosingDate(options[key], isQueryForBundle);
        break;
      case "rateLockExpiryDate":
        mustQuery = getRateLockExpiryDate(options[key]);
        break;
      case "loanSubmissionDate":
        mustQuery = getloanSubmissionDate(options[key]);
        break;
      case "purchaseDate":
        mustQuery = getPurchaseDate(options[key], isQueryForBundle);
        break;
      case "loan.loanState.raw":
        mustQuery = getLoanStateQuery(options[key]);
        break;
      default:
        mustQuery = {
          terms: {
            [key]: options[key]
          }
        };
        break;
    }

    if (options[key].length) {
      if (mustQuery) {
        if (isQueryAnArray) {
          queryObject.push(...mustQuery);
        } else {
          queryObject.push(mustQuery);
        }
      }
      validQuery = true;
    }
  });
  return validQuery ? model(queryObject, updatedMustNot) : null;
}

export function getTKOPEsQuery(
  filterOptions: ObjectType | null,
  globalSearchKey: string,
  sideFilterObjPayload: any,
  index: number = 0,
  withAggs: boolean = false,
  size: number = 20,
  isMustExistForUnAssignedLoan?: boolean,
  isMustExistForDDReviewInitiatedLoan?: boolean,
  isMustExistForDDReviewNonInitiatedLoan?: boolean,
  isMustNotExist?: boolean,
  partnerId?: string | null
) {
  const mustNotArray: ObjectType[] = [];
  let mustArray: any = [
    globalSearchKey !== "" && {
      multi_match: {
        analyzer: "standard",
        fields: [
          "loan.loanDetailId.toorakLoanId",
          "loan.loanInfo.primaryLoanId"
        ],
        query: globalSearchKey,
        type: "phrase_prefix"
      }
    }
  ].filter(Boolean);

  mustArray = addGlobalFilters(mustArray, sideFilterObjPayload);

  const filterQuery = createQuery(
    filterOptions ? { ...filterOptions } : {},
    filterModel,
    "filter",
    mustNotArray
  );

  const requestBody = {
    query: {
      bool: {
        must: mustArray,
        must_not: mustNotArray
      }
    },
    _source: tkopSourceFields,
    sort: [
      {
        updatedOn: "desc"
      },
      "_score"
    ],
    track_total_hits: true,
    aggs: withAggs ? unAssignedLoanAggsQuery : {},
    from: index,
    size
  };

  // General query prepared using all applied filter for all type of loan which
  // later modified based on type
  requestBody.query.bool.must = [
    ...requestBody.query.bool.must,
    ...(filterQuery?.query?.bool?.must ?? [])
  ];
  if (isMustExistForUnAssignedLoan) {
    // Discard any loanState if passed in filter while preparing qurey
    // since unassigned loan can have only initial review state
    const mustQuery = requestBody.query.bool.must.filter((item: any) =>
      item.terms ? Object.keys(item.terms)[0] !== "loan.loanState.raw" : true
    );
    requestBody.query.bool.must = mustQuery;
    requestBody.query.bool.must.push(...defaultMustArrayForUnassignedLoan);
  }
  if (isMustExistForDDReviewInitiatedLoan) {
    const isLoanStatePresent: boolean =
      !!filterOptions &&
      Object.keys(filterOptions).some((item) => item === "loan.loanState.raw");

    const isLoanStateHasInitialReview: boolean =
      !!filterOptions &&
      Object.keys(filterOptions).some(
        (item) =>
          item === "loan.loanState.raw" &&
          filterOptions[item].includes("Initial Review - Approved")
      );
    let tempDefaultMustArrayForDDInitiatedAssignedLoan;
    // Discard default loanState from default query if loan state is other than
    // "initial review" with in filter while preparing qurey
    if (isLoanStatePresent && !isLoanStateHasInitialReview) {
      tempDefaultMustArrayForDDInitiatedAssignedLoan =
        defaultMustArrayForDDInitiatedAssignedLoan.filter((item) => {
          if (item.terms) {
            return !(Object.keys(item.terms)[0] === "loan.loanState.raw");
          }
          return true;
        });
    }
    // Discard  "initial review" filter if applied on ddinitailed loana as
    // it can not have this loanState
    if (isLoanStateHasInitialReview) {
      requestBody.query.bool.must = requestBody.query.bool.must.filter(
        (item: any) => {
          if (item.terms) {
            return !(Object.keys(item.terms)[0] === "loan.loanState.raw");
          }
          return true;
        }
      );
    }

    // update new query with updated filter
    if (isLoanStatePresent && !isLoanStateHasInitialReview) {
      requestBody.query.bool.must = requestBody.query.bool.must.concat(
        tempDefaultMustArrayForDDInitiatedAssignedLoan
      );
    } else {
      requestBody.query.bool.must = requestBody.query.bool.must.concat(
        defaultMustArrayForDDInitiatedAssignedLoan
      );
    }

    // Remove "FES" loanStage filter from DDReview loan query as it can not have this
    // filter. If there is single filter "FES" then discard whole loanSatge filter
    // within query
    if (
      !!filterOptions &&
      Object.keys(filterOptions).some(
        (item) =>
          item === "loan.loanStage.raw" && filterOptions[item].includes("FES")
      )
    ) {
      requestBody.query.bool.must = requestBody.query.bool.must.map(
        (item: any) => {
          if (
            item.terms &&
            Object.keys(item.terms)[0] === "loan.loanStage.raw"
          ) {
            item.terms["loan.loanStage.raw"] = item.terms[
              "loan.loanStage.raw"
            ].filter((e: any) => e !== "FES");
          }
          return item;
        }
      );

      requestBody.query.bool.must = requestBody.query.bool.must.filter(
        (item: any) => {
          if (
            item.terms &&
            Object.keys(item.terms)[0] === "loan.loanStage.raw"
          ) {
            return item.terms["loan.loanStage.raw"].length > 0;
          }
          return false;
        }
      );
    }
    requestBody.query.bool.must_not.push({
      terms: {
        "loan.loanInfo.takeoutPartnerStatus.raw": ["PENDING"]
      }
    });
    requestBody.query.bool.must.push({
      terms: {
        "loan.loanInfo.takeoutPartner.raw": [partnerId]
      }
    });
  }
  if (isMustExistForDDReviewNonInitiatedLoan) {
    // Discard any loanState if passed in filter while preparing qurey
    // since DDRNonInitiated loan can have only initial review state
    const mustQuery = requestBody.query.bool.must.filter((item: any) =>
      item.terms ? Object.keys(item.terms)[0] !== "loan.loanState.raw" : true
    );
    requestBody.query.bool.must = mustQuery;
    requestBody.query.bool.must.push(
      ...defaultMustArrayForDDNotInitiatedAssignedLoan
    );
    // check if FES filter applied if yes then attach extra loanState
    // "submit" within loan State
    if (
      !!filterOptions &&
      Object.keys(filterOptions).some(
        (item) =>
          item === "loan.loanStage.raw" && filterOptions[item].includes("FES")
      )
    ) {
      defaultMustArrayForDDNotInitiatedAssignedLoan =
        defaultMustArrayForDDNotInitiatedAssignedLoan.map((ddnitem) => {
          if (
            ddnitem.terms &&
            Object.keys(ddnitem.terms)[0] === "loan.loanState.raw"
          ) {
            // eslint-disable-next-line no-param-reassign
            ddnitem.terms["loan.loanState.raw"] = [
              ...(ddnitem?.terms?.["loan.loanState.raw"] ?? []),
              "submit"
            ];
          }
          return ddnitem;
        });
    }
    requestBody.query.bool.must_not.push({
      terms: {
        "loan.loanInfo.takeoutPartnerStatus.raw": ["PENDING"]
      }
    });
    requestBody.query.bool.must.push({
      terms: {
        "loan.loanInfo.takeoutPartner.raw": [partnerId]
      }
    });
  }
  return requestBody;
}

export function getEsQuery(
  filterOptions: ObjectType | null,
  globalSearchKey: string,
  sideFilterObjPayload: any,
  index: number = 0,
  withAggs: boolean = false,
  size: number = 20,
  selectedLoanIds?: any,
  sourceFields?: string[],
  addOrgPartyIds?: boolean,
  addDefaultMustNot?: boolean,
  isQueryForBundle?: boolean,
  sortQueryForBundle?: boolean
) {
  let accountIds: any = localStorage.getItem("account_ids");
  const mustNotArray = addDefaultMustNot ? defaultMustNotArray : [];
  accountIds = JSON.parse(accountIds);
  let mustArray: any = [
    globalSearchKey !== "" && {
      bool: {
        should: [
          {
            multi_match: {
              analyzer: "standard",
              fields: [
                "loan.loanDetailId.toorakLoanId",
                "loan.loanInfo.primaryLoanId",
                "bundleId",
                "bundle.bundleId"
              ],
              query: globalSearchKey,
              type: "phrase_prefix"
            }
          }
        ]
      }
    }
  ].filter(Boolean);

  if (isQueryForBundle && globalSearchKey !== "") {
    mustArray[0].bool.should.push({
      nested: {
        path: "loans",
        query: {
          multi_match: {
            analyzer: "standard",
            fields: ["loans.loanId"],
            query: globalSearchKey,
            type: "phrase_prefix"
          }
        }
      }
    });
  }

  mustArray = addGlobalFilters(mustArray, sideFilterObjPayload);

  const requestBody = {
    query: {
      bool: {
        must: mustArray,
        must_not: mustNotArray
      }
    },
    _source: sourceFields,
    sort: [
      sortQueryForBundle
        ? {
            "loan.loanDetailId.loanId.raw": "asc"
          }
        : {
            updatedOn: "desc"
          },
      "_score"
    ],
    track_total_hits: true,
    aggs: withAggs ? loansAggsQuery : {},
    from: index,
    size
  };

  const filterQuery = createQuery(
    filterOptions ? { ...filterOptions } : {},
    filterModel,
    "filter",
    mustNotArray,
    isQueryForBundle
  );
  const loanOriginatorPartyIdsQuery = [
    {
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": accountIds
      }
    }
  ];
  const bundleOriginatorPartyIdsQuery = [
    {
      terms: {
        "originatorPartyId.raw": accountIds
      }
    }
  ];
  const originatorPartyIdsQuery = isQueryForBundle
    ? bundleOriginatorPartyIdsQuery
    : loanOriginatorPartyIdsQuery;
  requestBody.query.bool.must = [
    ...requestBody.query.bool.must,
    ...(filterQuery?.query?.bool?.must ?? []),
    ...(addOrgPartyIds ? originatorPartyIdsQuery : [])
  ];
  requestBody.query.bool.must_not =
    filterQuery?.query?.bool?.must_not ?? mustNotArray;
  if (selectedLoanIds?.length > 0) {
    requestBody.query.bool.must = [
      ...requestBody.query.bool.must,
      {
        terms: {
          "loan.loanDetailId.toorakLoanId.raw": selectedLoanIds
        }
      },
      {
        terms: {
          "loan.loanDetailId.loanId.raw": selectedLoanIds
        }
      }
    ];
  }
  return requestBody;
}

export function getLoanList(
  filterOptions: ObjectType | null,
  globalSearchKey: string,
  sideFilterObjPayload: any,
  index: number = 0,
  withAggs: boolean = false,
  size: number = 20,
  selectedLoanIds?: any,
  doNotaddDefaultMustNot?: boolean,
  isQueryForBundle?: boolean,
  sortQueryForBundle?: boolean
) {
  const query = getEsQuery(
    filterOptions,
    globalSearchKey,
    sideFilterObjPayload,
    index,
    withAggs,
    size,
    selectedLoanIds,
    loanSourceFields,
    !isRole(UserRole.LAT_TREASURER),
    !doNotaddDefaultMustNot,
    isQueryForBundle,
    sortQueryForBundle
  );
  return getLoansList(query);
}

export function getLoanListQuery(
  filterOptions: ObjectType | null,
  globalSearchKey: string,
  sideFilterObjPayload: any,
  index: number = 0,
  withAggs: boolean = false,
  size: number = 20,
  isMustExistForUnAssignedLoan?: boolean,
  isMustExistForDDReviewInitiatedLoan?: boolean,
  isMustExistForDDReviewNonInitiatedLoan?: boolean,
  isMustNotExist?: boolean,
  partnerId?: string
) {
  return getTKOPEsQuery(
    filterOptions,
    globalSearchKey,
    sideFilterObjPayload,
    index,
    withAggs,
    size,
    isMustExistForUnAssignedLoan,
    isMustExistForDDReviewInitiatedLoan,
    isMustExistForDDReviewNonInitiatedLoan,
    isMustNotExist,
    partnerId
  );
}

export function getBundleList(
  filterOptions: ObjectType | null,
  globalSearchKey: string,
  sideFilterObjPayload: any,
  index: number = 0,
  withAggs: boolean = false,
  size: number = 20,
  selectedLoanIds?: any
) {
  const query = getEsQuery(
    filterOptions,
    globalSearchKey,
    sideFilterObjPayload,
    index,
    withAggs,
    size,
    selectedLoanIds,
    bundleSourceFields,
    !isRole(UserRole.LAT_TREASURER),
    false,
    true
  );
  return getBundles(query);
}

export function getFiltersOptions(bucketKeys: string[], selectedView: number) {
  const bucketObj: ObjectType = {};
  let accountIds: any = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  bucketKeys.forEach((key: string) => {
    bucketObj[key] = {
      terms: {
        field: `${key}`,
        size: 100,
        order: {
          _term: "asc"
        }
      }
    };
  });
  const orgPartyIdKey =
    selectedView === 0
      ? "loan.loanDetailId.originatorPartyId.raw"
      : "originatorPartyId.raw";
  const requestBody = {
    query: {
      bool: {
        must: [
          {
            terms: {
              [orgPartyIdKey]: accountIds
            }
          }
        ],
        must_not: selectedView === 0 ? defaultMustNotArray : []
      }
    },
    aggs: bucketObj,
    from: 0,
    size: 0
  };
  if (selectedView === 1) {
    return getBundles(requestBody);
  }
  return getFiltersData(requestBody);
}

export const useMultiSelection = (data: ObjectType) => {
  const [selectedItems, setSelectedItems] = useState<Map<string, ObjectType>>(
    new Map()
  );
  const { key = "id" } = data;

  const updateSelectedItems = (checked: boolean, details: ObjectType) => {
    setSelectedItems((selectedItems: Map<string, ObjectType>) => {
      const updatedSelectedItems = new Map(selectedItems);
      if (checked) {
        updatedSelectedItems.set(details?.[key], details);
      } else {
        updatedSelectedItems.delete(details?.[key]);
      }
      return updatedSelectedItems;
    });
  };

  return { selectedItems, setSelectedItems, updateSelectedItems };
};
