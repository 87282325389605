/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BridgeLoanGuarantorInformation,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
import { BorrowerInformation, LoanDetails } from "./create-loan.reducerHelper";
import {
  bridgeLoanFields,
  thirtyYearLoanFields
} from "./constants/loanFieldType";
import { checkFieldValidity, isEmptyValue } from "../utils/formatChecks";
import {
  fesBridgeLoan,
  fesThirtyYearLoan,
  fesBridgeProperty,
  fesThirtyYearProperty,
  LoanSizerEnum,
} from "../frontend-sizer/FrontendSizerTemplate";
import { getLoanType } from "../config/config";
import {
  rentDescriptionOld,
  rentDescription
} from "./constants/loanCreationDropDownValues";
import { getBorrowerInformationFields } from "../v2/fields/LoanDetails/BorrowerInformation";
import { getLoanInformationFields } from "../v2/fields/LoanDetails/LoanInformation";
import { getLoanEconomicsFields } from "../v2/fields/LoanDetails/LoanEconomics";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { getGuarantorInformationFields } from "../v2/fields/LoanDetails/GuarantorInformation";
import { getPropertyInformationFields } from "../v2/fields/PropertyDetails/PropertyInformation";
import { getPropertyEconomicsField } from "../v2/fields/PropertyDetails/PropertyEconomics";
import { getUnitInformationFields } from "../v2/fields/PropertyDetails/UnitInformation";

export function countFesErrors(
  data: LoanDetails,
  loanType: string,
  loanSizerType: LoanSizerEnum
) {
  let loanerrorCount = 0;
  const { fesBorrowerInformation, fesLoanEconomics, fesLoanInformation } = data;
  const fesBorrowerInfoFinal =
    fesBorrowerInformation?.foreignNationalString !== "No"
      ? {
        ...fesBorrowerInformation,
        borrowerCreditScore:
          isEmptyValue(fesBorrowerInformation.borrowerCreditScore) ||
            checkFieldValidity(fesBorrowerInformation.borrowerCreditScore, {
              fieldTpe: "number"
            })
            ? 400
            : fesBorrowerInformation.borrowerCreditScore
      }
      : fesBorrowerInformation;
  const loanFormData: any[] = [
    fesBorrowerInfoFinal,
    fesLoanEconomics,
    fesLoanInformation
  ];
  let templateKeys: {
    [index: string]: {
      fieldTpe: string;
      extraData?: any;
    };
  };
  if (loanType === getLoanType[0].displayValue) {
    fesBridgeLoan.forEach((item) => {
      item.fields
        .filter(
          (ele) =>
            (!ele.optionalCondition || !ele.optionalCondition(data)) &&
            (!ele.hideCondition || !ele.hideCondition({ loanSizerType }))
        )
        .forEach((ele) => {
          templateKeys = {
            ...templateKeys,
            ...{
              [ele.fieldName]: {
                fieldTpe: ele.fieldType,
                extraData: ele.extraData?.map((options) => options.value)
              }
            }
          };
        });
    });
  } else {
    fesThirtyYearLoan.forEach((item) => {
      item.fields
        .filter(
          (ele) =>
            (!ele.optionalCondition || !ele.optionalCondition(data)) &&
            (!ele.hideCondition || !ele.hideCondition({ loanSizerType }))
        )
        .forEach((ele) => {
          templateKeys = {
            ...templateKeys,
            ...{
              [ele.fieldName]: {
                fieldTpe: ele.fieldType,
                extraData: ele.extraData?.map((options) => options.value)
              }
            }
          };
        });
    });
  }
  loanFormData.forEach((element) => {
    loanerrorCount += Object.keys(element).filter((x: string) => {
      if (templateKeys[x]) {
        const fieldType = templateKeys[x] || { fieldTpe: "default" };
        if (!checkFieldValidity(element[x], fieldType)) {
          return true;
        }
        if (x === "borrowerCreditScore") {
          if (
            element[x] &&
            (Number(element[x]) < 300 || Number(element[x]) > 855)
          ) {
            return true;
          }
        }
      }
      return false;
    }).length;
  });
  return { loanerrorCount };
}
export function calculateFesPropertyError(
  data: any,
  loanType: string,
  loanSizerType: LoanSizerEnum
) {
  let propertyError = 0;
  const { fesPropertyEconomics, fesPropertyInformation, unitsInformation } =
    data;
  const propertyFormData: any[] = [
    fesPropertyEconomics,
    fesPropertyInformation
  ];

  if (loanType === getLoanType[1].displayValue) {
    const unitInfofieldObj = fesThirtyYearProperty.find(
      (ele) => ele.sectionName === "unitInformation"
    );
    if (unitInfofieldObj) {
      unitsInformation.forEach((unitInformation: any) => {
        unitInfofieldObj.fields
          .filter(
            (ele: any) =>
              !ele.requiredLeaseStatus ||
              (ele.requiredLeaseStatus &&
                ele.requiredLeaseStatus.includes(
                  unitInformation.payload?.leaseStatus
                ))
          )
          .forEach((item: any) => {
            let isValid = true;
            if (
              !item.optionalCondition ||
              !item.optionalCondition({
                unitInformation: unitInformation?.payload
              })
            ) {
              let dropDownValue =
                item.dropDownData &&
                item.dropDownData.map((ele: any) => ele.value);
              if (item?.reduxFieldName === "leaseStatus") {
                dropDownValue = [...rentDescriptionOld, ...rentDescription];
              }
              isValid = checkFieldValidity(
                unitInformation?.payload[item?.reduxFieldName],
                {
                  fieldTpe: item?.fieldType,
                  extraData: dropDownValue
                }
              );
            } else if (
              !isEmptyValue(unitInformation.payload[item.reduxFieldName])
            ) {
              isValid = checkFieldValidity(
                unitInformation.payload[item.reduxFieldName],
                {
                  fieldTpe: item.fieldType,
                  extraData:
                    item.dropDownData &&
                    item.dropDownData.map((ele: any) => ele.value)
                }
              );
            }
            if (!isValid) {
              propertyError += 1;
            }
          });
      });
    }
  }
  let templateKeys: {
    [index: string]: {
      fieldTpe: string;
      extraData?: any;
    };
  };
  if (loanType === getLoanType[0].displayValue) {
    fesBridgeProperty.forEach((item) => {
      item.fields
        .filter(
          (ele) =>
            (!ele.optionalCondition || !ele.optionalCondition(data)) &&
            (!ele.hideCondition || !ele.hideCondition({ loanSizerType }))
        )
        .forEach((ele) => {
          templateKeys = {
            ...templateKeys,
            ...{
              [ele.fieldName]: {
                fieldTpe: ele.fieldType,
                extraData: ele.extraData?.map((options) => options.value)
              }
            }
          };
        });
    });
  } else {
    fesThirtyYearProperty
      .filter((ele) => ele.sectionName !== "unitInformation")
      .forEach((item) => {
        item.fields.forEach((ele) => {
          templateKeys = {
            ...templateKeys,
            ...{
              [ele.fieldName]: {
                fieldTpe: ele.fieldType,
                extraData:
                  ele.extraData?.map((options) => options.value) ||
                  ele.dropDownData?.map((options) => options.value)
              }
            }
          };
        });
      });
  }
  propertyFormData.forEach((element) => {
    propertyError += Object.keys(element.payload).filter((x: string) => {
      if (templateKeys[x]) {
        const fieldType = templateKeys[x] || { fieldTpe: "default" };
        if (!checkFieldValidity(element.payload[x], fieldType)) {
          return true;
        }
      }
      return false;
    }).length;
  });
  return propertyError;
}

const creditScoreCheck = (
  foreignNationalString: string,
  creditScore: string
) => {
  return (foreignNationalString !== "Yes" && foreignNationalString !== "")
    ? Number(creditScore) < 300 || Number(creditScore) > 855
      ? ""
      : creditScore
    : isEmptyValue(creditScore)
      ? 400
      : creditScore;
};

export function countErrors(
  data: LoanDetails,
  loanType: string,
  loanStage: LoanStage,
  bridgeIsGroundUpLoan: boolean
) {
  const isBridge = loanType === getLoanType[0].displayValue;
  const isDSCR = loanType === getLoanType[1].displayValue;
  if (isBridge) {
    const {
      bridgeLoanBorrowerInformation,
      bridgeLoanEconomics,
      bridgeLoanGuarantorInformation,
      bridgeLoanInformation
    } = data;

    const loanFormData: any[] = [
      bridgeLoanEconomics.payload,
      bridgeLoanInformation.payload
    ];
    const firstEntityBorrowerId = bridgeLoanBorrowerInformation.find(
      (ele: any) => ele.payload.borrowerType === "Entity"
    )?.borrowerId;
    const hasIndividualBorrower = bridgeLoanBorrowerInformation.find(
      (ele: any) => ele.payload.isPrimaryBorrower
    );
    bridgeLoanBorrowerInformation.forEach(
      (item: {
        borrowerId: string;
        errors: number | null;
        payload: any; //add to BridgeLoanBorrowerInformation
        // payload: BridgeLoanBorrowerInformation;
      }) => {
        const {
          isPrimaryBorrower,
          borrowerEmail,
          borrowerType,
          firstName,
          lastName,
          foreignNationalString,
          originalCreditScoreMedian,
          originalCreditReportDate,
          borrowerExperience,
          heavyRehabExperience,
          borrowerGUCExperience,
          billingAddress,
          billingPhoneNumber,
          borrowingEntityName
        } = item.payload;
        if (isPrimaryBorrower) {
          let obj = {
            borrowerEmail,
            borrowerType,
            firstName,
            lastName,
            foreignNationalString,
            originalCreditScoreMedian: creditScoreCheck(
              foreignNationalString,
              originalCreditScoreMedian
            ),
            originalCreditReportDate,
            // borrowerExperience,
            // heavyRehabExperience,
            billingAddress,
            billingPhoneNumber
          };
          if (bridgeIsGroundUpLoan) {
            obj = {
              ...obj,
              ...{ heavyRehabExperience, borrowerGUCExperience }
            };
          } else {
            obj = { ...obj, ...{ borrowerExperience } };
          }
          loanFormData.push(obj);
        } else if (
          !hasIndividualBorrower &&
          firstEntityBorrowerId === item.borrowerId
        ) {
          let obj = {
            borrowerEmail,
            borrowerType,
            borrowingEntityName,
            // borrowerExperience,
            // heavyRehabExperience,
            billingAddress,
            billingPhoneNumber
          };
          if (bridgeIsGroundUpLoan) {
            obj = {
              ...obj,
              ...{ heavyRehabExperience, borrowerGUCExperience }
            };
          } else {
            obj = { ...obj, ...{ borrowerExperience } };
          }
          loanFormData.push(obj);
        } else if (hasIndividualBorrower && !isPrimaryBorrower) {
          // in below section some values are defaulted to random values if they are empty. This is because, this block in which we create non primary borrower object, these fields can be left empty. But in case they have value, they need to have valid values
          const obj = {
            originalCreditScoreMedian: creditScoreCheck(
              foreignNationalString,
              originalCreditScoreMedian
            ),
            foreignNationalString: isEmptyValue(foreignNationalString)
              ? "Yes"
              : foreignNationalString,
            borrowerEmail: isEmptyValue(borrowerEmail)
              ? "abc@gg.com"
              : borrowerEmail,
            originalCreditReportDate: isEmptyValue(originalCreditReportDate)
              ? "2021-12-12"
              : originalCreditReportDate,
            borrowerExperience: isEmptyValue(borrowerExperience)
              ? 1
              : borrowerExperience,
            billingAddress: isEmptyValue(billingAddress)
              ? "billingAddress"
              : billingAddress,
            billingPhoneNumber: isEmptyValue(billingPhoneNumber)
              ? "20211212"
              : billingPhoneNumber
          };
          loanFormData.push(obj);
        }
      }
    );
    bridgeLoanGuarantorInformation.forEach(
      (item: {
        guarantorId: string;
        errors: number | null;
        payload: BridgeLoanGuarantorInformation;
      }) => {
        // if (
        //   item.payload.isPrimaryGuarantor || isBridge
        // ) {
        loanFormData.push({
          creditScore: creditScoreCheck(
            item.payload.foreignNationalString,
            item.payload.creditScore
          ),
          guarantorLastName: item.payload.guarantorLastName,
          guarantorFirstName: item.payload.guarantorFirstName,
          originalCreditReportDate: item.payload.originalCreditReportDate,
          foreignNationalString: item.payload.foreignNationalString,
          isPrimaryGuarantor: item.payload.isPrimaryGuarantor,
          guarantorEmail: item.payload.guarantorEmail,
          guarantorExperience: item.payload.guarantorExperience,
          pOEntity: item.payload.pOEntity
        });
        // }
        // else {
        //   loanFormData.push(
        //     !item.payload.creditScore
        //       ? {
        //           guarantorFirstName: item.payload.guarantorFirstName,
        //           guarantorLastName: item.payload.guarantorLastName,
        //           guarantorEmail: item.payload.guarantorEmail,
        //           guarantorExperience: item.payload.guarantorExperience,
        //           pOEntity: item.payload.pOEntity
        //         }
        //       : {
        //           creditScore:
        //             item.payload.foreignNationalString === "No"
        //               ? Number(item.payload.creditScore) < 300 ||
        //                 Number(item.payload.creditScore) > 855
        //                 ? ""
        //                 : item.payload.creditScore
        //               : isEmptyValue(item.payload.creditScore)
        //               ? 400
        //               : item.payload.creditScore,
        //           guarantorFirstName: item.payload.guarantorFirstName,
        //           guarantorLastName: item.payload.guarantorLastName,
        //           guarantorEmail: item.payload.guarantorEmail,
        //           guarantorExperience: item.payload.guarantorExperience,
        //           pOEntity: item.payload.pOEntity
        //         }
        //   );
        // }
      }
    );
    let loanerrorCount = 0;
    loanFormData.forEach((element) => {
      loanerrorCount += Object.keys(element).filter((x) => {
        const fieldType = bridgeLoanFields[x] || { fieldTpe: "default" };
        if (fieldType.isHidden && fieldType.isHidden(data)) {
          // console.log(x, fieldType.isHidden(data));
          return false;
        }
        if (
          !checkFieldValidity(element[x], fieldType) &&
          !(
            isEmptyValue(element[x]) &&
            fieldType.optionalIn &&
            fieldType.optionalIn.includes(loanStage)
          )
        ) {
          return true;
        }
        return false;
      }).length;
    });
    return { loanerrorCount };
  }
  if (isDSCR) {
    const {
      bridgeLoanBorrowerInformation,
      bridgeLoanGuarantorInformation,
      thirtyYearLoanInformation,
      thirtyYearLoanEconomics
    } = data;

    const loanFormData: any[] = [
      thirtyYearLoanInformation.payload,
      thirtyYearLoanEconomics.payload
    ];
    const firstEntityBorrowerId = bridgeLoanBorrowerInformation.find(
      (ele: any) => ele.payload.borrowerType === "Entity"
    )?.borrowerId;
    const hasIndividualBorrower = bridgeLoanBorrowerInformation.find(
      (ele: any) => ele.payload.isPrimaryBorrower
    );
    bridgeLoanBorrowerInformation.forEach(
      (item: { borrowerId: string; errors: number | null; payload: any }) => {
        const {
          isPrimaryBorrower,
          borrowerEmail,
          borrowerType,
          firstName,
          lastName,
          foreignNationalString,
          originalCreditScoreMedian,
          originalCreditReportDate,
          borrowerExperience,
          // heavyRehabExperience,
          billingAddress,
          billingPhoneNumber,
          borrowingEntityName
        } = item.payload;
        if (isPrimaryBorrower) {
          const obj = {
            borrowerEmail,
            borrowerType,
            firstName,
            lastName,
            foreignNationalString,
            originalCreditScoreMedian: creditScoreCheck(
              foreignNationalString,
              originalCreditScoreMedian
            ),
            originalCreditReportDate,
            billingAddress,
            billingPhoneNumber
          };
          loanFormData.push(obj);
        } else if (
          !hasIndividualBorrower &&
          firstEntityBorrowerId === item.borrowerId
        ) {
          const obj = {
            borrowerEmail,
            borrowerType,
            borrowingEntityName,
            billingAddress,
            billingPhoneNumber
          };
          loanFormData.push(obj);
        } else if (hasIndividualBorrower && !isPrimaryBorrower) {
          const obj = {
            originalCreditScoreMedian: creditScoreCheck(
              foreignNationalString,
              originalCreditScoreMedian
            ),
            foreignNationalString: isEmptyValue(foreignNationalString)
              ? "Yes"
              : foreignNationalString,
            borrowerEmail: isEmptyValue(borrowerEmail)
              ? "abc@gg.com"
              : borrowerEmail,
            originalCreditReportDate: isEmptyValue(originalCreditReportDate)
              ? "2021-12-12"
              : originalCreditReportDate,
            borrowerExperience: isEmptyValue(borrowerExperience)
              ? 1
              : borrowerExperience,
            billingAddress: isEmptyValue(billingAddress)
              ? "billingAddress"
              : billingAddress,
            billingPhoneNumber: isEmptyValue(billingPhoneNumber)
              ? "20211212"
              : billingPhoneNumber
          };
          loanFormData.push(obj);
        }
      }
    );
    bridgeLoanGuarantorInformation.forEach(
      (item: {
        guarantorId: string;
        errors: number | null;
        payload: BridgeLoanGuarantorInformation;
      }) => {
        if (item?.payload?.isPrimaryGuarantor) {
          loanFormData.push({
            creditScore: creditScoreCheck(
              item.payload.foreignNationalString,
              item.payload.creditScore
            ),
            guarantorLastName: item.payload.guarantorLastName,
            guarantorFirstName: item.payload.guarantorFirstName,
            originalCreditReportDate: item.payload.originalCreditReportDate,
            foreignNationalString: item.payload.foreignNationalString,
            isPrimaryGuarantor: item.payload.isPrimaryGuarantor,
            guarantorEmail: item.payload.guarantorEmail,
            guarantorExperience: item.payload.guarantorExperience,
            pOEntity: item.payload.pOEntity
          });
        } else {
          loanFormData.push(
            !item?.payload?.creditScore
              ? {
                guarantorFirstName: item?.payload?.guarantorFirstName,
                guarantorLastName: item?.payload?.guarantorLastName,
                guarantorEmail: item?.payload?.guarantorEmail,
                guarantorExperience: item?.payload?.guarantorExperience,
                pOEntity: item?.payload?.pOEntity
              }
              : {
                creditScore: creditScoreCheck(
                  item?.payload?.foreignNationalString,
                  item?.payload?.creditScore
                ),
                guarantorFirstName: item?.payload?.guarantorFirstName,
                guarantorLastName: item?.payload?.guarantorLastName,
                guarantorEmail: item?.payload?.guarantorEmail,
                guarantorExperience: item?.payload?.guarantorExperience,
                pOEntity: item?.payload?.pOEntity
              }
          );
        }
      }
    );
    let loanerrorCount = 0;
    loanFormData.forEach((element) => {
      loanerrorCount += Object.keys(element).filter((x) => {
        const fieldType = thirtyYearLoanFields[x] || { fieldTpe: "default" };
        if (fieldType.isHidden && fieldType.isHidden(data)) {
          return false;
        }
        if (
          !checkFieldValidity(element[x], fieldType) &&
          !(
            isEmptyValue(element[x]) &&
            fieldType?.optionalIn &&
            fieldType?.optionalIn?.includes(loanStage)
          )
        ) {
          return true;
        }
        return false;
      }).length;
    });
    return { loanerrorCount };
  }
  return null;
}

export function countLoanDetailError(loanStage: any, loanType: string, loanDetails: any, updatedFields: any, loanEvaluationResult: any, loanId: any) {
  const isBridge = loanType === getLoanType[0].displayValue;
  const isDSCR = loanType === getLoanType[1].displayValue;
  const checkSubmission = (loanStateVal: any) => {
    return ![
      LoanStatusEnum.DataEntry,
      LoanStatusEnum.ExcelError,
      LoanStatusEnum.ExcelSuccess
    ].includes(loanStateVal);
  };
  const isSubmitted = checkSubmission(loanDetails?.loanState);

  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanEconomics,
    bridgeLoanGuarantorInformation,
    bridgeLoanInformation,
    thirtyYearLoanInformation,
    thirtyYearLoanEconomics,
    fesBorrowerInformation,
    fesLoanInformation,
    fesLoanEconomics,
    loanSizerType,
    loanDetailId
  } = loanDetails;

  const loanInformationFields: any = getLoanInformationFields(loanStage.toLowerCase(), loanType, loanSizerType, loanDetailId);
  const loanEconomicsFields: any = getLoanEconomicsFields(loanStage.toLowerCase(), loanType, loanSizerType);

  if (loanStage !== LoanStage.fes) {
    const loanEconomicsData = isBridge ? bridgeLoanEconomics?.payload : thirtyYearLoanEconomics?.payload;
    let loanInformationData = isBridge ? bridgeLoanInformation?.payload : thirtyYearLoanInformation?.payload;

    loanInformationData = {
      ...loanInformationData,
      accrualDate: loanEvaluationResult?.loanResult?.[loanId]?.settlementResults?.accrualDate || "",
      nextPaymentDueDate: loanEvaluationResult?.loanResult?.[loanId]?.settlementResults?.nextPaymentDueDate || ""
    }
    let loanErrorCount = 0;
    let bErrorFields: any = [];

    const reqLoanInfoFields: any = loanInformationFields?.filter((loanInfo: any) => loanInfo.required() && loanInfo.isVisible(loanInformationData, loanType, true, {}));
    const reqLoanEconomicsFields: any = loanEconomicsFields?.filter((loanEconomics: any) => loanEconomics.required(loanEconomicsData) && loanEconomics.isVisible(loanEconomicsData, loanType, true, {}));

    const isRequired = (item: any, borrowerInfo: any, borrowersData: any) => {
      const hasIndividualBorrower = borrowersData.find((ele: any) => ele.payload.isPrimaryBorrower);
      const firstEntityBorrowerId = borrowersData?.find(
        (ele: any) =>
          ele.payload?.borrowerType === "Entity"
      )?.borrowerId;

      let required =
        (borrowerInfo.isPrimaryBorrower &&
          !bridgeLoanFields[item.fieldName]?.optionalIn?.includes(loanStage)) ||
        (!hasIndividualBorrower &&
          borrowerInfo?.borrowerId === firstEntityBorrowerId &&
          !bridgeLoanFields[item.fieldName]?.optionalIn?.includes(loanStage));

      if (required && item.fieldName === "originalCreditScoreMedian") {
        if (borrowerInfo.foreignNationalString === "Yes") {
          required = false;
        }
      }
      return required;
    };
    let areEmailsValid = true;
    let areExperienceValid = true;

    bridgeLoanBorrowerInformation.map((borrowerInformation: any, idx: number) => {
      const borrowerInformationFields: any = getBorrowerInformationFields(loanStage.toLowerCase(), loanType, loanInformationData?.selectedToorakProduct);
      const reqBorrowerFields: any = borrowerInformationFields?.filter((borrowerInfo: any) => (borrowerInfo?.required(borrowerInformation?.payload, bridgeLoanBorrowerInformation, loanStage)) && borrowerInfo.isVisible(borrowerInformation?.payload, loanType, true, {}));

      borrowerInformationFields?.forEach((borrowerField: any, bIdx: number) => {
        if(!areEmailsValid || !areExperienceValid) return;
        const key = borrowerField.backendKey || borrowerField.fieldName;
        const value = updatedFields?.borrowerInfo?.[idx]?.[key] ? (updatedFields?.borrowerInfo?.[idx]?.[key]) : (borrowerInformation?.payload?.[borrowerField?.fieldName]);
        if (!["borrowerExperience", "experience", "borrowerGUCExperience", "heavyRehabExperience", "email", "borrowerEmail"].includes(key)) return;
        const isValueValid = (key === "borrowerEmail" || key === "email" ) && value ? checkFieldValidity(value, {
          fieldTpe: "email"
        }) : true;
        const isExperienceValid = (["borrowerExperience", "experience", "borrowerGUCExperience", "heavyRehabExperience"].includes(key)) && value ? checkFieldValidity(value, {
          fieldTpe: "number"
        }) : true;

        areEmailsValid = areEmailsValid && isValueValid;
        areExperienceValid = areExperienceValid && isExperienceValid;
      });
      bErrorFields = reqBorrowerFields?.filter((borrowerField: any, bIdx: number) => {
        const key = borrowerField.backendKey || borrowerField.fieldName;
        return ((updatedFields?.borrowerInfo?.length && updatedFields?.borrowerInfo[idx]?.[key]) ? isEmptyValue(updatedFields?.borrowerInfo[idx]?.[key]) : isEmptyValue(borrowerInformation?.payload?.[borrowerField.fieldName]));
      });
    });

    let guarantorErrorFields: any[] = []; // Initialize guarantorErrorFields with an empty array

    bridgeLoanGuarantorInformation.map((guarantorInformation: any, idx: number) => {
      const guarantorInformationFields: any = getGuarantorInformationFields(loanStage.toLowerCase());
      const reqGuarantorFields: any = guarantorInformationFields?.filter((guarantorInfo: any) => guarantorInfo.required() && guarantorInfo.isVisible(guarantorInformation?.payload, loanType, true, {}));
      guarantorInformationFields?.forEach((guarantorField: any, bIdx: number) => {
        if (!areEmailsValid) return;
        const key = guarantorField.backendKey || guarantorField.fieldName;
        const value = updatedFields?.guarantorInfo?.[idx]?.[key] ? (updatedFields?.guarantorInfo?.[idx]?.[key]) : (guarantorInformation?.payload?.[guarantorField?.fieldName]);
        if (key !== "guarantorEmail" && key !== "email") return;
        const isValueValid = (key === "guarantorEmail" || key === "email") && value ? checkFieldValidity(value, {
          fieldTpe: "email"
        }) : true;
        areEmailsValid = areEmailsValid && isValueValid;
      });
      guarantorErrorFields = reqGuarantorFields?.filter((guarantorField: any, bIdx: number) => {
        const key = guarantorField.backendKey || guarantorField.fieldName;
        return Boolean(((updatedFields?.guarantorInfo?.length && updatedFields?.guarantorInfo[idx]?.[key]) ? isEmptyValue(updatedFields?.guarantorInfo[idx]?.[key]) : isEmptyValue(guarantorInformation?.payload?.[key])));
      });
    });
    const loanInfoErrorFields = reqLoanInfoFields?.filter((loanInfo: any) => {
      if (loanInfo && ["nextPaymentDueDate", "accrualDate"].includes(loanInfo.fieldName)) {
        const key = loanInfo.backendKey || loanInfo.fieldName;
        return isSubmitted && !updatedFields.loanResult?.[loanId]?.settlementResults[key]
      }
      return updatedFields?.loanInfo?.[loanInfo.backendKey || loanInfo.fieldName] ? (isEmptyValue(updatedFields?.loanInfo?.[loanInfo.backendKey || loanInfo.fieldName])) : (isEmptyValue(loanInformationData?.[loanInfo.fieldName]));
    });
    const loanEcoErrorFields = reqLoanEconomicsFields?.filter((loanEcoField: any) => {
      return updatedFields?.loanEconomics?.[loanEcoField.backendKey || loanEcoField.fieldName] ? (isEmptyValue(updatedFields?.loanEconomics?.[loanEcoField.backendKey || loanEcoField.fieldName])) : (isEmptyValue(loanEconomicsData?.[loanEcoField.fieldName]));
    });
    loanErrorCount += ((bErrorFields?.length || 0) + (guarantorErrorFields?.length || 0) + (loanInfoErrorFields?.length || 0) + (loanEcoErrorFields?.length || 0));
    return { loanErrorCount, areEmailsValid, areExperienceValid };
  } else {
    let loanErrorCount = 0;

    const updatedFesLoanEconomics = {
      ...fesLoanEconomics
    }
    const borrowerInformationFields: any = getBorrowerInformationFields(loanStage.toLowerCase(), loanType, loanDetails?.loanSizerType);
    const reqLoanInfoFields: any = loanInformationFields?.filter((loanInfo: any) => loanInfo.required(fesLoanInformation) && loanInfo.isVisible(fesLoanInformation, loanType, true, {}));
    const reqLoanEconomicsFields: any = loanEconomicsFields?.filter((loanEconomics: any) => loanEconomics.required(updatedFesLoanEconomics) && loanEconomics.isVisible(updatedFesLoanEconomics, loanType, true, {}));
    const reqBorrowerFields: any = borrowerInformationFields?.filter((borrowerInfo: any) => borrowerInfo.required(fesBorrowerInformation) && borrowerInfo.isVisible(fesBorrowerInformation, loanType, true, {}));

    const borrowerErrorFields = reqBorrowerFields?.filter((borrowerField: any, idx: number) => (updatedFields?.borrowerInfo?.length && updatedFields?.borrowerInfo[0]?.[borrowerField.backendKey || borrowerField.fieldName]) ? isEmptyValue(updatedFields?.borrowerInfo?.length && updatedFields?.borrowerInfo[0]?.[borrowerField.backendKey || borrowerField.fieldName]) : isEmptyValue(fesBorrowerInformation?.[borrowerField.fieldName]));
    const loanInfoErrorFields = reqLoanInfoFields?.filter((loanInfoField: any) => isEmptyValue(updatedFields?.loanInfo?.[loanInfoField.backendKey || loanInfoField.fieldName]) && isEmptyValue(fesLoanInformation?.[loanInfoField.fieldName]));
    const loanEcoErrorFields = reqLoanEconomicsFields?.filter((loanEcoField: any) => isEmptyValue(updatedFields?.loanEconomics?.[loanEcoField.backendKey || loanEcoField.fieldName]) && isEmptyValue(updatedFesLoanEconomics?.[loanEcoField.fieldName]));
    loanErrorCount += ((borrowerErrorFields?.length || 0) + (loanInfoErrorFields?.length || 0) + (loanEcoErrorFields?.length || 0));
    return { loanErrorCount };
  }
}

export function countPropertyDetailError(loanStage: any, loanType: string, loanDetails: any, updatedFields: any, propertyData: any) {
  let loanTypeStr = loanType === "InvestorDSCR" ? "dscr" : "bridge";
  let propertyErrorCount = 0;
  let unitsInfoErrorCount = 0;

  const loanSizerType = loanDetails?.loanSizerType;
  const {
    propertyEconomics, unitsInformation, propertyInformation, fesPropertyEconomics, fesPropertyInformation
  } = propertyData;

  loanTypeStr = loanTypeStr + loanStage?.toLowerCase();

  const propertyInformationFields: any = getPropertyInformationFields(loanStage.toLowerCase(), loanType, loanSizerType);
  const propertyEconomicsFields: any = getPropertyEconomicsField(loanStage.toLowerCase(), loanType, loanSizerType);

  const propertyEconomicsData = loanStage === LoanStage.fes ? fesPropertyEconomics?.payload : propertyEconomics?.payload;
  const propertyInformationData = loanStage === LoanStage.fes ? fesPropertyInformation?.payload : propertyInformation?.payload;

  const reqPropertyInfoFields: any = propertyInformationFields?.filter((propertyInfo: any) => propertyInfo.required() && propertyInfo.isVisible(propertyInformationData, loanType, true, {}));
  const reqPropertyEconomicsFields: any = propertyEconomicsFields?.filter((propertyEconomics: any) => propertyEconomics.required(propertyEconomicsData) && propertyEconomics.isVisible(propertyEconomicsData, loanType, true, {}));

  const propertyInfoErrorFields = reqPropertyInfoFields?.filter((propertyInfoField: any) => isEmptyValue(updatedFields?.propertyinfo?.[propertyInfoField.fieldName]) && isEmptyValue(propertyInformationData?.[propertyInfoField.fieldName]));
  const propertyEconomicsField = reqPropertyEconomicsFields?.filter((propertyEcoField: any) => isEmptyValue(updatedFields?.propertyEconomics?.[propertyEcoField.fieldName]) && isEmptyValue(propertyEconomicsData?.[propertyEcoField.fieldName]));

  // units error calculation:
  if (unitsInformation.length && loanType === getLoanType[1].displayValue) {
    unitsInformation.map((unitInfo: any, idx: number) => {
      const rentalCharacterization = unitInfo.payload?.leaseStatus;
      const unitInformationFields: any = getUnitInformationFields(loanTypeStr, rentalCharacterization);
      const reqUnitFields: any = unitInformationFields?.filter((unitInfoField: any) => unitInfoField.required() && unitInfoField.isVisible(unitInfo.payload, loanType, true, {}));

      const unitErrorFields = reqUnitFields?.filter((unitField: any, uIdx: number) => {
        const value = updatedFields?.unitsInfo?.length ? (updatedFields?.unitsInfo[idx]?.[unitField.backendKey] || updatedFields?.unitsInfo[idx]?.[unitField.fieldName]) : (unitInfo.payload?.[unitField.backendKey] || unitInfo.payload?.[unitField.fieldName])
        return isEmptyValue(value);
      });

      unitsInfoErrorCount += unitErrorFields?.length || 0;
    });
  }
  propertyErrorCount += ((propertyInfoErrorFields?.length || 0) + (propertyEconomicsField?.length || 0) + (unitsInfoErrorCount || 0));
  return { propertyErrorCount };
}

export function includesPrimaryBorrowerOrGuarantor(
  borrowerArray: BorrowerInformation[],
  guarantorArray: any,
  loanType: string = ""
) {
  let hasPrimaryBorrowerOrGuarantor = false;
  const individualBorrower = borrowerArray.find(
    (item) => item.payload.isPrimaryBorrower
  );
  if (individualBorrower) {
    hasPrimaryBorrowerOrGuarantor = true;
  } else {
    let individualGuarantor = false;
    if (loanType === getLoanType[0].displayValue) {
      individualGuarantor = !!guarantorArray?.length;
    } else {
      individualGuarantor = guarantorArray.find(
        (item: any) => item.payload.isPrimaryGuarantor
      );
    }
    const entityBorrower = borrowerArray.find(
      (item) => item.payload.borrowerType === "Entity"
    );
    if (individualGuarantor && entityBorrower) {
      hasPrimaryBorrowerOrGuarantor = true;
    }
  }
  return hasPrimaryBorrowerOrGuarantor;
}
