import { LoanStructureTypes, ToorakProductEnum, condoEligibilityDropDown, condoEligibilityV2DropDown, loanPurposeDropDown, loanStructureDropDown, thirtyYearLoanPurpose, thirtyYearToorakProduct, yesNoDropdown } from "../create-loan/constants/loanCreationDropDownValues";

export const borrowerTypeDropDownValues = [
  { label: "Entity", value: "Entity" },
  { label: "Individual", value: "Individual" }
];

export const borrowerTypes = ["Entity", "Individual"];
export const yOrNDropDown = ["Y", "N"];

export const getLoanType = {
  0: {
    name: "Bridge Loan",
    displayValue: "BridgeLoan",
    loanTypeId: 2,
    loanCreationName: "Bridge Loan",
    settlementTabPath: "Bridge"
  },
  1: {
    name: "Investor DSCR",
    displayValue: "InvestorDSCR",
    loanTypeId: 1,
    loanCreationName: "30 Year Loan",
    settlementTabPath: "DSCR"
  },
  2: {
    name: "2 + 1 Loan",
    displayValue: "TwoPlusOneLoan",
    loanTypeId: 3,
    loanCreationName: "2 + 1 Loan"
  },
  3: {
    name: "Bridge Toorval Loan",
    displayValue: "ToovalBridge",
    loanTypeId: 4,
    loanCreationName: "Toorval Bridge Loan"
  }
};

export const thirtyYrLoanPurposes = thirtyYearLoanPurpose.map((item: any) => ({
  value: item,
  label: item
}));

export const thirtyYearSelectedCross = yesNoDropdown.map((item) => ({
  value: item,
  label: item
}));

export const recourseDropDownValues = [
    {
      dataTestId: "radio-recourse-yes",
      label: "Full Recourse",
      value: "Full Recourse"
    },
    {
      dataTestId: "radio-recourse-no",
      label: "Non-Recourse",
      value: "Non-Recourse"
    }
  ];

const thirtyYearRentalLabel = "30YR Rental";

export const toorakProductDropDown = [
  ToorakProductEnum.Rehab,
  ToorakProductEnum.Rental,
  ToorakProductEnum.GroundUp
];

export const toorakProductValues = thirtyYearToorakProduct.map(item => ({
  value: item,
  label: thirtyYearRentalLabel
}));

export const condoEligibilityValues = condoEligibilityDropDown.map(item => ({
  value: item,
  label: item
}));

export const condoEligibilityV2Values = condoEligibilityV2DropDown.map((item) => ({
  value: item,
  label: item
}));

export const thirtyYearCreditEvent = yesNoDropdown.map((item) => ({
  value: item,
  label: item
}));

export const prepaymentPenalty = [60, 48, 36, 24, 12, 0];

export const prepaymentPenaltyTypeDropDown = [
  "5/5/5/5/5",
  "5/4/3/2/1",
  "1/1/1/1/1",
  "4/3/2/1",
  "1/1/1/1",
  "5/4/3",
  "3/2/1",
  "1/1/1",
  "2/1",
  "1/1",
  "1",
  "2",
  "0"
];

export const rateType = ["Fixed", "Adjustable"];

export const numberType = "numeric";

export const textType = "text";
// const textDescription = "textDescription";
export const decimalType = "decimal";

export const accuralTypeDropDown = ["Actual/360", "30/360"];

export const loanStructureDropDownValue = loanStructureDropDown
  ?.filter((ele) => ele === LoanStructureTypes.singleDraw)
  .map((item) => ({
    value: item,
    label: item
  }));

 export const loanStructureValues = loanStructureDropDown.map((item) => ({
    value: item,
    label: item
  }));

  export const loanPurposeDropDownValues = loanPurposeDropDown.map((item) => ({
    value: item,
    label: item
  }));

  export const loanTypeConv: any = {
    Bridge: "Bridge Loan",
    DSCR: "30 Year Loan"
  };
  